//@flow
import React from 'react';
import Page from '../components/Page';
import Button from '../components/button';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Logo from '../icons/Logo';
import { query } from '../style';

export default () => (
  <Page>
    <Background>
      <StyledLogo animated={false} />
      <Title>404: Page Not Found</Title>
      <P>
        You just requested a page that doesn't exist. It's possible you followed
        an outdated link.
      </P>
      <Button arrow onClick={() => navigate('/')}>
        Go home
      </Button>
      <Space />
    </Background>
  </Page>
);

const Background = styled.div`
  background: #6772e5;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 530px;
`;

const Title = styled.h1`
  color: white;
`;
const P = styled.p`
  color: white;
  opactiy: 0.9;
  font-size: 18px;
  margin-bottom: 80px;
  text-align: center;
`;
const StyledLogo = styled(Logo)`
  position: absolute;
  left: 15px;
  top: 15px;
  width: 140px;
  height: 60px;
  ${query(420)`
    width: 230px;
    height: 110px;
  `}
`;

const Space = styled.div`
  height: 15%;
`;
